import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const Button = ({
  children,
  type = 'button',
  imagePickerButton = false,
  width,
  height,
  font,
  ...rest
}) => (
  <Container
    type={type}
    styling={rest.styling}
    {...rest}
    imagePickerButton={imagePickerButton}
    width={width}
    height={height}
    font={font}
  >
    {children}
    {rest.styling === 'searchSubmit'}
  </Container>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  imagePickerButton: PropTypes.bool,
  width: PropTypes.number,
  type: PropTypes.string,
  height: PropTypes.number,
  font: PropTypes.number,
};

export default Button;
