import styled, { css } from 'styled-components';

import { shade } from 'polished';

const ButtonVariations = {
  landingPage: css`
    background-color: #f3ba79;
    font-style: normal;
    line-height: 20px;
    font-weight: 700;
    font-size: 14px;
    color: #653390;

    &:hover {
      background: ${shade(0.2, '#f3ba79')};
    }
  `,
  landingPageRegister: css`
    background-color: #f3ba79;
    font-style: normal;
    line-height: 20px;
    font-weight: 700;
    font-size: 19px;
    color: #653390;

    @media screen and (max-width: 768px) {
      max-width: 400px;
    }

    @media screen and (max-width: 468px) {
      max-width: 300px;
    }

    &:hover {
      background: ${shade(0.2, '#f3ba79')};
    }
  `,

  submit: css`
    background-color: #653391;
    /* font-family: 'Roboto'; */
    font-weight: bold;
    &:hover {
      background: ${shade(0.2, '#653391')};
    }
  `,
  error: css`
    background-color: #db190d;
    &:hover {
      background: ${shade(0.2, '#db190d')};
    }
  `,
  success: css`
    background-color: #049d13;
    &:hover {
      background: ${shade(0.2, '#049d13')};
    }
  `,
  disabled: css`
    background-color: #d6d8db;
    /* font-family: 'Roboto'; */
    font-weight: bold;
    color: #747a85;
    cursor: not-allowed;
  `,
  leaked: css`
    background-color: transparent;
    border: 1px solid #7c52a1;
    color: #7c52a1;
    /* font-family: 'Roboto'; */
    font-weight: bold;
  `,

  cardListShow: css`
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background-color: #7c52a1;
  `,
  cardListEdit: css`
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background-color: #7c52a1;
  `,

  searchSubmit: css`
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background-color: #7c52a1;
    position: relative;

    img {
      position: absolute;
      top: 0;
      right: 0;
    }
  `,
  whiteButton: css`
    background-color: #ffffff;
    border-radius: 100px;
    border: none;
    color: #7c52a1;
    font-weight: bold;
  `,
};

export const Container = styled.button`
  border-radius: 100px;
  border: 0;
  padding: 0 16px;
  color: #fff;
  width: ${props => (props.width ? `${props.width}px` : '100%')};
  height: ${props => (props.height ? `${props.height}px` : '100%')};
  transition: background-color 0.2s;

  ${props =>
    props.petshopRegister &&
    css`
      p {
        /* font-family: Montserrat; */
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.0015em;
      }
    `}

  ${props => ButtonVariations[props.styling || 'submit']}

  ${props =>
    props.cardListShow &&
    css`
      min-width: 97px;

      max-width: 130px;
      height: 24px;
      background: white;
      border-radius: 100px;
      border: 1px solid #7c52a1;
      margin-right: 6px;
      /* margin-left: 4px; */

      &:hover {
        background: ${shade(0.2, 'white')};
      }

      p {
        text-transform: none;
        color: #7c52a1;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
      }
    `}


  ${props =>
    props.cardListEdit &&
    css`
      min-width: 70px;

      max-width: 70px;
      height: 24px;
      background: #7c52a1;
      border-radius: 100px;
      border: 1px solid #7c52a1;

      p {
        text-transform: none;
        color: white;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
      }
    `}

  ${props =>
    props.cardListCancel &&
    css`
      width: 24px;
      padding: 10px;
      height: 24px;
      background: #7c52a1;
      border-radius: 100%;
      border: 1px solid #7c52a1;

      display: flex;
      align-items: center;
      justify-content: center;

      margin-left: 6px;
      p {
        text-transform: none;
        color: white;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
      }
    `}




  ${props =>
    props.imagePickerButton &&
    css`
      margin-left: 15px;
      margin-top: 249px;
      width: 296px;
      height: 40px;
      border: 1px solid #d6d8db;
      border-radius: 30px;

      p {
        text-transform: none;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        color: white;
        line-height: 18px;
      }
    `} /* ${props =>
    props.imagePickerButton && props.styling !== 'disabled'
      ? css`
          p {
            color: white;
          }
        `
      : css`
          p {
            color: #747a85;
          }
        `} */
`;
