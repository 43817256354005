import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

*{
  margin:0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}
*:focus{
  outline: 0;
}
html, body, #root{
  height:100%
}
body{
  -webkit-font-smoothing: antialiased;
  background-color: #7C52A1;

}
body, input, button {
  font: 14px -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
}
a {
  text-decoration: none;
}
ul {
  list-style: none;
}
button {
  cursor: pointer;
}
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
  }

html,body, #root {
  height: 100vh;
  font-family: -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

  body {
    background-color: #7C52A1;
  }

  button {
    cursor: pointer;
  }

 
`;
